import {gsap} from 'gsap'

let homepage = document.querySelector('.home')

let windowWidth = window.innerWidth;

    gsap.set('#content-section section .gsap-anim', {autoAlpha: 0, y: '1rem'});
    gsap.set('#content-section section .gsap-anim-list', {autoAlpha: 0, y: '1rem'});
    gsap.set('#content-section section .gsap-anim-content *', {autoAlpha: 0, y: '1rem'});

    if (windowWidth > 1024) {
        gsap.set('#content-section section .gsap-anim-image', {autoAlpha: 0, x: '1rem'});
    } else {
        gsap.set('#content-section section .gsap-anim-image', {autoAlpha: 0, x: '-1rem'});
    }





const animateVisible = (block, ratio, isIntersecting) => {
    // When the element is in view:
    if (ratio > 0 && isIntersecting) {
        // Animate all the children of this element
        gsap.to(block.querySelectorAll('.gsap-anim'), {
            duration: 0.6,
            // animate back to visible
            autoAlpha: 1,
            // animate back to top
            y: '0',
            stagger: 0.2,

            ease: 'power3.inOut',
        });
        gsap.to(block.querySelectorAll('.gsap-anim-list'), {
            duration: 0.3,
            // animate back to visible
            autoAlpha: 1,
            // animate back to top
            y: '0',
            stagger: 0.1,

            ease: 'power3.inOut',
        });
        gsap.to(block.querySelectorAll('.gsap-anim-content *'), {
            duration: 0.4,
            // animate back to visible
            autoAlpha: 1,
            // animate back to top
            y: '0',
            stagger: 0.2,

            ease: 'power3.inOut',
        });
        gsap.to(block.querySelectorAll('.gsap-anim-image'), {
            duration: 1.2,
            // animate back to visible
            autoAlpha: 1,
            // animate back to top
            x: '0',
            stagger: 0.3,
            delay: 0.3,
            ease: 'power3.inOut',
        });
    } else {
        // When not inview


    }
};
//check the sections



    const blocks = document.querySelectorAll(' #content-section section');
    const blocksObserver = new IntersectionObserver(
        entries => {
            // entries === elements
            return entries.forEach(event => {
                const {target, intersectionRatio, isIntersecting} = event;
                // Call function
                animateVisible(target, intersectionRatio, isIntersecting);
            });
        },
        //trigger only when above threshold
        {threshold: 0.2}
    );
    for (const block of blocks) {
        blocksObserver.observe(block);
    }
