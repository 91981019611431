var $employeeMails = $('.employee-list .employees-wrapper .employee .content a.button p');
var $text;
var $splittedText;
/*
if ($employeeMails.length > 0) {
    $employeeMails.each(function (index) {
        $text = $(this).text();
        $splittedText = $text.split('@');
        $(this).html('<span>' + $splittedText[0] + '</span>' + '@' + '<span>' + $splittedText[1] + '</span>');
    });
}
*/
