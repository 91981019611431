var $navLink = $('.menu-content .menu-item-has-children li a');
var $navLinkArrow = $('.menu-content .menu-item-has-children button.chevron');
var breakpoint = 1440;
var $submenu = $('.menu-content .menu-item-has-children .submenu');
var $submenuLink = $('.menu-content .menu-item-has-children .submenu li a');
$('.menu-content .menu-item-has-children').removeClass('active');

$($submenuLink).click(function (e) {
    window.location = $(this).href;
});

$($navLinkArrow).click(function (e) {
        e.preventDefault();
        if ($(this).parent().hasClass('submenu-open')) {
            $(this).parent().removeClass('submenu-open');
        }
        else {
            $navLink.parent().removeClass('submenu-open')
            $(this).parent().addClass('submenu-open');
        }
});

$($navLink).click(function (e) {

        if ($(this).parent().hasClass('submenu-open')) {
            setTimeout(function() {
                $(this).parent()().removeClass('submenu-open');
            }, 100);
        }
        else {
            $navLink.parent().removeClass('submenu-open')
            $(this).parent().addClass('submenu-open');
        }

});

$navLink.hover(function () {
    if ($(this).parent().parent().hasClass('active')) {
        $(this).parent().parent().removeClass('active');
    }
    else {
        $(this).parent().parent().addClass('active');
    }
});

$submenu.hover(function () {
    if ($(this).parent().hasClass('active')) {
        $(this).parent().removeClass('active');
    }
    else {
        $(this).parent().addClass('active');
    }
});
