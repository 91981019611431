import * as THREE from "three";
import {
    OrbitControls
} from "three/examples/jsm/controls/OrbitControls.js";
import {
    CSS2DRenderer,
    CSS2DObject
} from 'three/examples/jsm/renderers/CSS2DRenderer.js';
/*
* var ip = [{
    name: "Norway",
    lat: 58.969975,
    lon: 5.710386,
},
    {
        name: "USA",
        lat: 45.182036837015886,
        lon: -94.61425781250001,
    },
    {
        name: "Australia",
        lat: -32.175612478499325,
        lon: -244.16015625000003,
    },
];*/


var sphere ;
//THREE JS setup variables
var camera, scene, renderer, labelRenderer, earth;
if(document.querySelector('.location')){
    init();
    animate();
}

function init() {
    const textureLoader = new THREE.TextureLoader();
    var globevizContainer = document.getElementById('globeViz');
    var earthContainer = document.getElementById('earth-container');
    var labelContainer = document.getElementById('label-container');

    const EARTH_RADIUS = 225;
    const EARTH_SEGMENTS = 75;
    const EARTH_RINGS = 75;

    camera = new THREE.PerspectiveCamera(45, globevizContainer.clientWidth / globevizContainer.clientHeight, 0.1, 2000);
    camera.position.set(450, 380, -75);
    scene = new THREE.Scene();
    window.camera = camera;

    var light = new THREE.AmbientLight(0xffffff, 1.5);
    camera.add(light);

    scene.add(camera);

    //Create group to hold sphere and texture, makes up the earth.
    earth = new THREE.Group();
    scene.add(earth);

    // Create the sphere
    var sphere = new THREE.SphereBufferGeometry(EARTH_RADIUS, EARTH_SEGMENTS, EARTH_RINGS);
    sphere.clearGroups();
    sphere.addGroup( 0, Infinity, 0 );
    sphere.addGroup( 0, Infinity, 1 );

    // Map the texture to the material.
    var material = new THREE.MeshMatcapMaterial({
        transparent: true,
        opacity: 1,
        map: textureLoader.load( homeUrl+'/wp-content/themes/grensesnitt/static/images/Presserv_sf2_globe-1.png' ),
        specularMap: textureLoader.load( homeUrl+'/wp-content/themes/grensesnitt/static/images/earth_specular_2048.jpg' ),
        normalMap: textureLoader.load( homeUrl+'/wp-content/themes/grensesnitt/static/images/earth_normal_2048.jpg' ),
    });
    var clouds = new THREE.MeshMatcapMaterial({
        transparent: true,
        opacity: 1,
        map: textureLoader.load( homeUrl+'/wp-content/themes/grensesnitt/static/images/Presserv_sf2_skyer_lr.png' ),

    });

    var materials = [material,clouds]

    // Create a new mesh with sphere geometry.
    var mesh = new THREE.Mesh(sphere, materials);




    // Add mesh to globe
    earth.add(mesh);

    scene.add(earth);
    sphere = clouds;

    //Add IPs to Earth
    for (var i = 0; i < ip.length; i++) {

        if (ip[i].lat != null && ip[i].lon != null) {
            var name = ip[i].name;
            var slug = ip[i].slug;
            var lat = ip[i].lat;
            var lon = ip[i].lon;
            var urlLink = ip[i].url


            //Create dot for each ip

            var nameSlug = slugify(name, '-')
            var ipDiv = document.createElement('a');
            ipDiv.href = urlLink;

            ipDiv.className = 'three-dot';
            ipDiv.setAttribute("id", nameSlug);
            ipDiv.setAttribute("title", name);
            ipDiv.style.backgroundColor = "red";

            //Create label to append to each ip's dot
            var ipDivLabel = document.createElement('label');
            ipDivLabel.className = "three-label";
            ipDivLabel.setAttribute("id", nameSlug + '_label');
            ipDivLabel.setAttribute("title",'Click for '+ name );
            ipDivLabel.textContent = name;
            ipDiv.appendChild(ipDivLabel);


            var ipDot = new CSS2DObject(ipDiv);

            ipDiv.parent = ipDot;
            ipDot.element.style.cursor = "pointer";

            var handleLocationClick = function(e) {
                var a = e.target;

                // We might have click the a > label element, but we are looking for the actual a element (the parent)
                if (a.tagName === 'LABEL') {
                    a = a.parentElement;
                }

                location.href = a.href;
            };

            // Intercept clicks and touches on locations before the OrbitControls hijacks them
            // https://github.com/mrdoob/three.js/issues/16254#issuecomment-483334305
            ipDot.element.addEventListener('mousedown', handleLocationClick, true);
            ipDot.element.addEventListener('touchstart', handleLocationClick, true);

            var ipPosition = calcPosFromLatLonRad(lat, lon, EARTH_RADIUS);


            ipDot.position.set(ipPosition[0], ipPosition[1], ipPosition[2]);

            earth.add(ipDot);

        }
    }

    renderer = new THREE.WebGLRenderer({
        alpha: true
    });


    renderer.setClearColor(0xffffff, 0);
    renderer.setPixelRatio(window.devicePixelRatio );
    renderer.setSize(globevizContainer.clientWidth, globevizContainer.clientHeight);
    earthContainer.appendChild(renderer.domElement);

    labelRenderer = new CSS2DRenderer();
    labelRenderer.setSize(globevizContainer.clientWidth, globevizContainer.clientHeight);
    labelRenderer.domElement.style.position = 'absolute';
    labelRenderer.domElement.style.top = 0;

    labelContainer.appendChild(labelRenderer.domElement);

    //For rotation and mouse controls
    var controls = new OrbitControls(camera, labelRenderer.domElement);
    controls.minDistance = 700;
    controls.maxDistance = 700;
    controls.enableZoom = false;
    controls.enabled = false;

    window.addEventListener( 'resize', onWindowResize );


}

function onWindowResize(){
    var globevizContainer = document.getElementById('globeViz');
    camera.aspect =    globevizContainer.clientWidth / globevizContainer.clientHeight;

    camera.updateProjectionMatrix();

    renderer.setSize(  globevizContainer.clientWidth, globevizContainer.clientHeight);

    labelRenderer.setSize(  globevizContainer.clientWidth, globevizContainer.clientHeight );

}

function animate() {
    requestAnimationFrame(animate);
    renderer.render(scene, camera);
    labelRenderer.render(scene, camera);
    //sphere.group(1).rotation.y += 0.02;


}

/* Referenced from answer found here:
 https://stackoverflow.com/questions/28365948/javascript-latitude-longitude-to-xyz-position-on-earth-threejs
 */
function calcPosFromLatLonRad(lat, lon, radius) {

    var phi = (90 - lat) * (Math.PI / 180)
    var theta = (lon + 180) * (Math.PI / 180)

    var x = -((radius) * Math.sin(phi) * Math.cos(theta))
    var z = ((radius) * Math.sin(phi) * Math.sin(theta))
    var y = ((radius) * Math.cos(phi))

    return [x, y, z];
}

function slugify(str, separator) {
    str = str.trim();
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    const to = "aaaaaaeeeeiiiioooouuuunc------";

    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    return str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-") // collapse dashes
        .replace(/^-+/, "") // trim - from start of text
        .replace(/-+$/, "") // trim - from end of text
        .replace(/-/g, separator);
}
