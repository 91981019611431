var menuButton = document.querySelector('.menu-button');
var header = document.getElementsByTagName('header')[0];
var body = document.getElementsByTagName('body')[0];
var navMenu = document.querySelector('.nav-menu');
var contactBtn = document.querySelector('.mail-icon')

var contactForm = document.querySelector('.contact-form');
var closeBtn = document.querySelector('.formCloseBtn');

$('a[href="#contact-form"]').on('click', function(e) {
    e.preventDefault();
    openContactForm()
});

if(window.location.hash == "#contact-form"){
    openContactForm()
}

$('a').on('click', function(e) {
    if($(this).attr("href").indexOf("#contact-form") >= 0 ){
        openContactForm()
    }
});

$('a').on('click', function(e) {
    if($(this).attr("href").indexOf("#location") >= 0 ){
        hideMenu()
    }
});



if (window.screen.width < 1440) {
    navMenu.classList.add('not-display');
}

function openContactForm(){
    contactForm.classList.add('opened')
}
function closeContactForm(){
    contactForm.classList.remove('opened')
}
if(closeBtn) {
    closeBtn.addEventListener('click', function () {
        closeContactForm()
    })
}

if(contactBtn){
contactBtn.addEventListener('click', function(){
    if(contactForm.classList.contains('opened')){
        closeContactForm()
    }else{
        openContactForm()
    }
})
}

function hideMenu() {
    menuButton.classList.remove('open');
    header.classList.remove('show-nav');
    setTimeout(function () {
        header.classList.remove('set-height');
    }, 500);
    body.classList.remove('no-scroll-y');
    setTimeout(function () {
        navMenu.classList.add('not-display');
    }, 500);
}

if (menuButton) {
    menuButton.addEventListener('click', function () {
        if (menuButton.classList.contains('open')) {
            hideMenu();
        } else {
            menuButton.classList.add('open');
            header.classList.add('set-height');
            setTimeout(function () {
                header.classList.add('show-nav');
            }, 100);
            body.classList.add('no-scroll-y');
            navMenu.classList.remove('not-display');
        }
    });
}
