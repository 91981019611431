let queryString = window.location.search
let windowParam = new URLSearchParams(queryString);
let countryGetter = windowParam.get('country')



var localStr = localStorage.getItem('countryString')


let logoTag = $('.site-logo')

let logos = $('.logo')
let normalLogo = $('.normal-logo')
let usaLogo = $('.usa-logo')
let ausLogo = $('.australia-logo')

let menus = $('.menu-target')
let normalMenu = $('.normal-menu')
let usaMenu = $('.cgsi-menu');
let ausMenu = $('.cgsa-menu');

let contactForms = $('.contact-wrapper')
let normalContact = $('.normal-contact')
let usaContact = $('.cgsi-contact')
let ausContact = $('.cgsa-contact')

//this checks localestorage for the usa string, australia to be added.
if(localStr == 'cgsi'){
    logos.each(function(){
        $(this).hide()
    })
    menus.each(function(){
        $(this).hide()
    })
    contactForms.each(function(){
        $(this).hide()
    })
    usaLogo.show()
    usaMenu.show()
    usaContact.show()


}else if(localStr == "cgsa"){
    logos.each(function(){
        $(this).hide()
    })
    menus.each(function(){
        $(this).hide()
    })
    contactForms.each(function(){
        $(this).hide()
    })
    ausLogo.show()
    ausMenu.show()
    ausContact.show()
}else{
    logos.each(function(){
        $(this).hide()
    })
    menus.each(function(){
        $(this).hide()
    })
    contactForms.each(function(){
        $(this).hide()
    })
    normalLogo.show()
    normalMenu.show()
    normalContact.show()
}

