
window.addEventListener("scroll", hideMenu)
window.addEventListener("scroll", colorMenu)
let isChanging = false;
var lastScrollTop = 0;


function hideMenu() {
    var openMenu = document.getElementsByClassName('nav-mobile-show')
    if(!openMenu.length) {
        var header = document.getElementById('header-container');
        var st = window.pageYOffset || document.documentElement.scrollTop;
        var siteLogos = $('.logo');
        var firstLocationSelector = $('.location-selector').first();
        if(window.innerWidth <= 768){
        if (st > lastScrollTop) {
            if (window.pageYOffset >= 78) {
                header.style.opacity = '0';
                header.style.pointerEvents = 'none'
            }
        } else {
            header.style.opacity = '1';
            header.style.pointerEvents = 'auto'

        }
        lastScrollTop = st <= 0 ? 0 : st;
        } else{
            if (st > lastScrollTop) {
                if (window.pageYOffset >= 78) {
                    siteLogos.each(function(){
                        $(this).css("opacity", "0");
                        $(this).css("pointer-events", "none");

                    })
                    firstLocationSelector.css("opacity", "0");
                    firstLocationSelector.css("pointer-events", "none");
                }
            } else {
                siteLogos.each(function(){
                    $(this).css("opacity", "1");
                    $(this).css("pointer-events", "auto");
                })
                firstLocationSelector.css("opacity", "1");
                firstLocationSelector.css("pointer-events", "auto");

            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
    }
}

function colorMenu (){
    let menuTop = document.querySelector('.location-selector')

    if(window.innerWidth >= 768) {

        if (isChanging) {
            return
        }

        if (window.pageYOffset >= 78 && !menuTop.classList.contains('compress')) {
            menuTop.classList.add('compress')

            isChanging = true;
            setTimeout(function () {
                isChanging = false;
            }, 300)
        } else if (window.pageYOffset < 78 && menuTop.classList.contains('compress')) {
            menuTop.classList.remove('compress')

            isChanging = true;
            setTimeout(function () {
                isChanging = false;
            }, 300)
        }

    }
}


