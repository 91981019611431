import Glide from '@glidejs/glide'
let glideSelector = document.querySelector('.glide')
if(glideSelector) {
    var glide = new Glide('.glide', {
        startAt: 0,
        perView: 4,
        gap: 64,
        bound: 4,
        peek: {
            before: 0,
            after: 0,
        },
        breakpoints: {
            600: {
                perView: 1,
                focusAt:"center",
                gap:16,
            },
            1024: {
                perView: 2,
                bound: 2,
                gap: 32,
            },
            1440: {
                perView: 3,
                bound: 3,
                gap: 32,
            },
            1920:{
                perview:4,
                bound: 4,
                gap: 40,
            }
        }
    })


    glide.mount()
}
