let sectionTags = $('section')

sectionTags.each(function(index){
    if(index == 2 ){
        $("#scrollBtn").attr("href","#" + $(this)[0].id)

    }

    })

$('#scrollBtn').click(function(e){
    scrollToAnchor(this.href, e, 0);

})


if (window.location.hash ) {
    scrollToAnchor(window.location.href, null, 0);
}

function scrollToAnchor(href, event, offset) {
    if (href.length <= 1) {
        return;
    }

    var hashIndex = href.indexOf('#');

    if (hashIndex === -1) {
        return;
    }

    var currentPath = window.location.origin + window.location.pathname;
    if (hashIndex > 0 && href.substring(0, hashIndex) !== currentPath) {
        return;
    }

    var id = href.substring(hashIndex + 1);

    if (!id) {
        return;
    }

    var $el = $('#' + id);

    if ($el.length === 0){
        return;
    }
    if(document.querySelector('.home')){
        $('html, body').animate({

            scrollTop: $el.offset().top - (offset || 0)
        }, 300);
    }else{
        $('html, body').animate({
            scrollTop: $el.offset().top - (offset || 0)
        }, 300);
    }


    if (event) {
        event.preventDefault();
        window.location.hash = id;
    }
}

window.scrollToAnchor = scrollToAnchor;
